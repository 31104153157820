import { notify } from 'components/Notifications/HotToastNotifications';
import {
  EDocumentResults,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import {
  createFraudulentDocumentUUID,
  getFraudResultsAction
} from 'redux/database/File Storage API/Actions';
import { UserInstance } from 'types/interfaces';
import * as gtag from 'Utils/gtag';

const mapApiResponseToEnum = (response: string): EDocumentResults => {
  switch (response) {
    case 'Acceptable':
      return EDocumentResults.ACCEPTABLE;
    case 'High Risk':
      return EDocumentResults.HIGH_RISK;
    case 'Risk':
      return EDocumentResults.RISK;
    case 'Low Risk':
      return EDocumentResults.LOW_RISK;
    default:
      return EDocumentResults.NONE;
  }
};

const handleDocumentProgress = async ({
  documentType,
  updateStatus,
  file,
  user
}: {
  documentType: EDocumentType;
  updateStatus: (status: string) => void;
  file: File;
  user: UserInstance;
}): Promise<{
  documentProgress: { documentUUID: string; documentStatus: EDocumentResults };
}> => {
  const documentProgress: {
    documentUUID: string;
    documentStatus: EDocumentResults;
  } = {
    documentUUID: '',
    documentStatus: EDocumentResults.NONE
  };

  if (documentType !== EDocumentType.OTHER) {
    try {
      updateStatus('Generating document key, please wait...');

      const UUID = await createFraudulentDocumentUUID({
        input: {
          type: documentType,
          side: 'front',
          file,
          account_holder: user.Title
        }
      });

      if (!UUID) {
        updateStatus('Failed to generate document key. Please try again.');
        notify.error('Failed to generate document key. Please try again.');
        return { documentProgress };
      }

      documentProgress.documentUUID = UUID;
      updateStatus('Checking document status, almost finished uploading...');

      const response = await getFraudResultsAction({
        collectionId: UUID
      });

      if (!response) {
        updateStatus('Failed to retrieve fraud results. Please try again.');
        notify.error('Failed to retrieve fraud results. Please try again.');

        return { documentProgress };
      }

      const status = response.result;
      const documentStatus = mapApiResponseToEnum(status);
      documentProgress.documentStatus = documentStatus;

      gtag.event({
        feature: 'File Explorer',
        action: 'Created Fraudulent Document',
        message: `${documentStatus}`
      });
    } catch (error) {
      updateStatus(
        'An error occurred while processing the document. Please try again.'
      );

      notify.error(`Error in handleDocumentProgress: ${error}`);
    }
  }

  return { documentProgress };
};

export { handleDocumentProgress };
