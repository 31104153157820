import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
import { IGetProcessFields } from 'types/databaseInterfaces';

/**
 * FIELD INSTANCE API
 *  */
export const GetProcessFields = async ({
  ProcessInstanceId,
  ProcessDefinitionId,
  action,
  ProcessStepDefinitionId
}: IGetProcessFields) => {
  let url = `FieldInstanceAPI.ashx?action=${action}&processinstanceid=${ProcessInstanceId}&processdefinitionid=${ProcessDefinitionId}`;
  if (ProcessStepDefinitionId) {
    url = `FieldInstanceAPI.ashx?action=${action}&processinstanceid=${ProcessInstanceId}&processdefinitionid=${ProcessDefinitionId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  }
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

// export const GetProcessFields = async({

// })

export const GetUserGeneratedDocument = async ({
  FieldInstanceId,
  ProcessInstanceId,
  ProcessStepDefinitionId,
  action = 'SINGLE'
}) => {
  const url = `FieldInstanceAPI.ashx?action=${action}&fieldinstanceid=${FieldInstanceId}&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}`;

  const config: AxiosRequestConfig = {
    method: 'get',
    url,
    ...(action === 'FILE' ? { responseType: 'blob' } : {})
  };

  return await dbQueue({ config, url });
};
