/** editable dropdown (input select option) */
import React, { useEffect, useContext, MouseEvent, useState } from 'react';
import {
  makeStyles,
  useTheme,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Tooltip
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { IMaterialUISelect, StyledSelect } from 'common/StyledSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import QuickUserFromId from 'components/User/QuickUserFromId';
import Box from '@material-ui/core/Box';
import { useTypedSelector } from 'redux/reducers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { NewPrimaryContactAutomation } from 'automations/NewContactAutomation';
import { CustomDialog } from 'common/Dialog';
import { DropDownContext } from './context';
import { Alert } from '@material-ui/lab';
import { EntityTypes } from 'components/User/NewUserButton/functions/entityTypes';

const useStyles = makeStyles((theme) => ({
  toEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '35px',
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: 5,
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      border: `1px ${theme.palette.primary.light} dashed`,
      paddingLeft: 10
    }
  },
  container: { width: '100%' }
}));

// Keyword On Backend "Alert": Is Used To Display a Message to the User Before Proceeding with an Action
const EditableDropDown = () => {
  const theme = useTheme();
  const {
    updating,
    edit,
    readOnly,
    openDialog,
    isUserTypeField,
    isRelatedUserTypeField,
    value,
    handleDialogClose,
    setOpen,
    handleFocus,
    handleSelectChange,
    options,
    open,
    detectRequired,
    ObjectDefinition
  } = useContext(DropDownContext);

  if (updating) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: '35px',
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius
        }}>
        <CircularProgress size={20} />
      </div>
    );
  }

  // Check if the required fields are filled out before allowing the user to proceed;
  const disabled = (): boolean => {
    const requiredFields = detectRequired && detectRequired();
    const useRequiredFieldsChecker = [3397, 3500];

    if (useRequiredFieldsChecker.includes(ObjectDefinition.Id)) {
      if (requiredFields && requiredFields?.length > 0) {
        return true;
      }
    }
    return false;
  };

  const isDisabled = disabled();
  if (!edit && !readOnly) {
    return (
      <>
        <CustomDialog
          maxSize="sm"
          open={openDialog}
          handleClose={async () => await handleDialogClose(false)}
          alert={{
            title: 'Action Confirmation Required:',
            description: `Agreeing will lock this object in "Read-Only" mode and prevent further edits.`,
            type: 'warning'
          }}>
          <DialogContent>
            <DialogContentText>
              {`
              By agreeing to proceed, this object will be set to "Read-Only"
              mode. You will not be able to make any further edits. Please
              confirm that you wish to continue with this action.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => await handleDialogClose(false)}
              color="primary">
              Cancel
            </Button>
            <Tooltip
              title={
                isDisabled ? 'Please Fill In Required Fields To Proceed' : ''
              }
              arrow>
              <span className={isDisabled ? 'disabledButtonWrapper' : ''}>
                <Button
                  disabled={isDisabled}
                  onClick={async () => await handleDialogClose(true)}
                  variant="contained"
                  color="primary"
                  autoFocus>
                  Confirm
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </CustomDialog>

        <NotEditingComponent />
      </>
    );
  }

  const StyleSelectProps: IMaterialUISelect = {
    value: value,
    open: open,
    handleClose: handleFocus,
    onChange: handleSelectChange,
    options: options,
    loading: options.length <= 0,
    sortAlphabetically: true,
    sortNumerically: true
  };

  const StyledSelectEntityTypeProps: IMaterialUISelect = {
    value: value,
    open: open,
    handleClose: handleFocus,
    onChange: handleSelectChange,
    options: EntityTypes,
    loading: EntityTypes.length <= 0,
    sortAlphabetically: true,
    sortNumerically: true
  };

  const EntityIds = 3464;
  if (ObjectDefinition.Id === EntityIds) {
    return (
      <>
        <Box width={'100%'}>
          <StyledSelect {...StyledSelectEntityTypeProps} useMaterialUI />
        </Box>
      </>
    );
  } else if (!readOnly) {
    return (
      <>
        <Box
          width={'100%'}
          onMouseDown={(event) => {
            event.preventDefault();
            setOpen(true);
          }}>
          <StyledSelect {...StyleSelectProps} useMaterialUI />
        </Box>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: '35px',
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius
        }}>
        {isUserTypeField || isRelatedUserTypeField ? (
          <QuickUserFromId UserInstanceId={value} />
        ) : (
          value
        )}
      </div>
    );
  }
};

export default EditableDropDown;

const NotEditingComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const landingpage = useTypedSelector((s) => s.utility.landingpage);
  const {
    readOnly,
    isUserTypeField,
    isRelatedUserTypeField,
    value,
    FieldDefinition,
    setEdit,
    setOpen
  } = useContext(DropDownContext);

  const handleFocus = () => {
    setOpen(true);
    setEdit(true);
  };

  // TODO: SORT OUT THE PROPAGATION, We're using on mouse down here is this for Ipad? need to check in staging as local host isn't running on 192 address

  return (
    <>
      <div
        onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
          console.log('onMouseDown');
          event.preventDefault();
          handleFocus();
        }}
        tabIndex={0}
        style={{ width: '100%' }}>
        {value ? (
          <div
            className={!readOnly ? classes.toEdit : ''}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   handleFocus();
            // }}
          >
            {isUserTypeField || isRelatedUserTypeField ? (
              <QuickUserFromId
                UserInstanceId={value}
                setEdit={setEdit}
                // handleParentClickEvent={(
                //   event: React.MouseEvent<HTMLDivElement>
                // ) => {
                //   event.stopPropagation();
                //   handleFocus();
                // }}
              />
            ) : (
              value
            )}

            {/* <ArrowDropDownIcon /> */}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              minHeight: '35px',
              background: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius
            }}>
            <Link
              data-cy="search-data-link"
              onClick={() => !readOnly && setEdit(true)}
              style={{ cursor: 'pointer' }}>
              {readOnly ? '' : '+ Select'}
            </Link>
          </div>
        )}
      </div>

      <div style={{ height: 2 }} />

      {FieldDefinition.Id === 23108 && !landingpage && (
        <NewPrimaryContactAutomation />
      )}
    </>
  );
}; //END NotEditingComponent
