import { IDealSummary } from 'hooks/useDealSummary/interface';
import moment from 'moment';
import {
  CompleteObjectInstance,
  CompleteObjectInstanceDict,
  CompleteProcessInstance,
  FieldInstance,
  UserInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { isEmptyOrTrue, isStructuredField } from '../functions';
import { patternToMomentFormat } from '../helper';
import {
  ELenderAPIType,
  ILabelValueOption,
  IMappedLenderIds,
  IStructuredField,
  LenderFieldDefinition,
  TLenderAPIInterfaces,
  TMappedIds,
  TTypeSafeRecord
} from '../interface';

interface IProposalCreationParams {
  selectedCONST: TLenderAPIInterfaces;
  mappedIds: TMappedIds;
  flattenedFieldInstances: IExtraFallenCompleteObjectInstance[];
  currentDeal: CompleteProcessInstance;
  user: UserInstance;
  entityType: string;
  dealSummary: IDealSummary;
}

interface IExtraFallenCompleteObjectInstance extends FieldInstance {
  isSelected: boolean | undefined;
}

/**
 * @typedef {Object} IProposalCreationParams
 * @property {TLenderAPIInterfaces} selectedCONST - The selected lender API interface
 * @property {TMappedIds} mappedIds - Mapped IDs for the lender API
 * @property {IExtraFallenCompleteObjectInstance[]} flattenedFieldInstances - Flattened field instances
 * @property {CompleteProcessInstance} currentDeal - The current deal instance
 * @property {UserInstance} user - The user instance
 * @property {string} entityType - The type of entity
 */

/**
 * Creates an internal structure for the lender API
 * @param {ICreateInternalStructureParams} params - The parameters for creating the internal structure
 * @returns {TLenderAPIInterfaces} The processed lender API interface
 */
const proposalCreation = ({
  selectedCONST,
  mappedIds,
  flattenedFieldInstances,
  currentDeal,
  user,
  entityType,
  dealSummary
}: IProposalCreationParams): TLenderAPIInterfaces => {
  // Define keyword actions for replacements
  const keywordActions = [
    { title: 'ProcessOwnerEmail', action: user.UserInstanceEmail },
    {
      title: 'ProcessInstanceId',
      action: currentDeal.ProcessInstance.Id.toString()
    },
    { title: 'BrokerName', action: user.Title },
    { title: 'BrokerNickname', action: user.NickName },
    { title: 'BrokerEmail', action: user.UserInstanceEmail },
    { title: 'currentDate', action: moment().format('YYYY-MM-DD') },
    {
      title: 'randomNumberX',
      action: `X${Array.from({ length: 5 }, () =>
        Math.floor(Math.random() * 10)
      ).join('')}`
    },
    {
      title: 'currentDateTime',
      action: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
  ];

  /**
   *? Helper function to get the field definition ID and forced value for EntityChange type fields.
   */
  const getFieldDefinitionId = (
    mappedIdsObj: Partial<IMappedLenderIds>
  ): { fieldDefinitionId: number; forcedValue?: string | number | boolean } => {
    const fieldDefinition = mappedIdsObj?.FieldDefinition;
    if (
      fieldDefinition &&
      fieldDefinition.type?.includes(ELenderAPIType.EntityChange)
    ) {
      const matchingEntity = fieldDefinition.newIdList?.find(
        (item) => item.entityType === entityType
      );
      if (matchingEntity) {
        return {
          fieldDefinitionId: matchingEntity.FieldDefinitionId,
          forcedValue: matchingEntity.forcedValue
        };
      }
    }
    return { fieldDefinitionId: mappedIdsObj?.FieldDefinitionId ?? 0 };
  };

  /**
   *? Handles splitting a full name into first and last names based on spaces.
   */
  const handleConcatenatedBySpace = (
    fullName: string,
    fieldName: string
  ): string => {
    const nameParts = fullName.trim().split(/\s+/);
    if (nameParts.length > 1) {
      const lowercaseFieldName = fieldName.toLowerCase();
      if (
        ['firstname', 'first_name', 'nameFirst'].includes(lowercaseFieldName)
      ) {
        return nameParts[0];
      } else if (
        ['lastname', 'last_name', 'surname', 'nameLast'].includes(
          lowercaseFieldName
        )
      ) {
        return nameParts.slice(1).join(' ');
      }
    }
    return fullName;
  };

  const getDefaultValueByType = (
    key: string,
    selectedCONST: TLenderAPIInterfaces
  ): string | number | boolean => {
    const flattenObject = (
      obj: any,
      prefix = '',
      res = {}
    ): Record<string, any> => {
      for (let k in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
          const newPrefix = prefix ? `${prefix}.${k}` : k;
          const value = obj[k];
          if (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value)
          ) {
            flattenObject(value, newPrefix, res);
          } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              const arrayPrefix = `${newPrefix}[${index}]`;
              if (typeof item === 'object' && item !== null) {
                flattenObject(item, arrayPrefix, res);
              } else {
                res[arrayPrefix] = item;
              }
            });
          } else {
            res[newPrefix] = value;
          }
        }
      }
      return res;
    };

    const flattenedSelectedCONST = flattenObject(selectedCONST);
    const matchingKey = Object.keys(flattenedSelectedCONST).find((k) =>
      k.includes(key)
    );

    const defaultValue = matchingKey
      ? flattenedSelectedCONST[matchingKey]
      : undefined;

    if (defaultValue === undefined) return '';
    switch (typeof defaultValue) {
      case 'boolean':
        return false;
      case 'number':
        return 0;
      case 'string':
        return '';
      default:
        return '';
    }
  };

  const normalizeValue = (
    value: any,
    key: string,
    selectedCONST: TLenderAPIInterfaces
  ): string | number | boolean => {
    let defaultType = typeof getDefaultValueByType(key, selectedCONST);
    if (defaultType === 'undefined') {
      if (typeof value === 'boolean') {
        defaultType = 'boolean';
      } else if (!isNaN(Number(value))) {
        defaultType = 'number';
      } else {
        defaultType = 'string';
      }
    }

    switch (defaultType) {
      case 'boolean':
        return value === true || value === 'true' || value === 1;
      case 'number':
        return !isNaN(Number(value)) ? Number(value) : 0;
      case 'string':
        return String(value);
      default:
        return value;
    }
  };

  /**
   * Processes an individual field, ensuring an IStructuredField is always returned.
   */
  const processField = (
    fieldValue: string | number | boolean,
    mappedIdsObj: IMappedLenderIds,
    currentKey: string,
    parentValues: Record<string, IStructuredField>
  ): IStructuredField | IStructuredField[] => {
    //* 1. Get field definition ID and forced value
    //* 2. Find matching fields from flattenedFieldInstances
    //* 3. Group matching fields by ObjectInstanceId
    //* 4. Process each group of fields
    //* 5. Handle special types (ConcatenatedBySpace, CascadingDropdown, EntityDependent)
    //* 6. Return processed field(s)

    const { fieldDefinitionId, forcedValue } =
      getFieldDefinitionId(mappedIdsObj);

    // Check if field has Hide type early
    if (mappedIdsObj?.FieldDefinition?.type) {
      const types = Array.isArray(mappedIdsObj.FieldDefinition.type)
        ? mappedIdsObj.FieldDefinition.type
        : [mappedIdsObj.FieldDefinition.type];

      if (types.includes(ELenderAPIType.Hide)) {
        return null as unknown as IStructuredField;
      }
    }

    // Find matching fields from flattenedFieldInstances
    let matchingFields = flattenedFieldInstances.filter(
      (field) => field.FieldDefinitionId === fieldDefinitionId
    );

    // If there are multiple matching fields, check for 'isSelected' field
    const selectedFields = matchingFields.filter((field) => field.isSelected);

    if (selectedFields.length > 0) {
      // Use only fields where 'isSelected' is true
      matchingFields = selectedFields;
    }

    // Group matching fields by ObjectInstanceId
    let fieldsByObjectInstanceId: Record<
      number,
      IExtraFallenCompleteObjectInstance[]
    > = {};

    if (matchingFields.length > 0) {
      fieldsByObjectInstanceId = groupBy(
        matchingFields,
        (field) => field.ObjectInstanceId
      );
    } else {
      const defaultFieldInstance: IExtraFallenCompleteObjectInstance = {
        FieldDefinitionId: fieldDefinitionId,
        FieldValue: '',
        ObjectInstanceId: 0,
        isSelected: false,
        Id: 0,
        Title: '',
        ProcessInstanceId: 0,
        ObjectDefinitionId: 0,
        UserInstanceId: 0,
        UserDefinitionId: 0
      };
      fieldsByObjectInstanceId = {
        0: [defaultFieldInstance]
      };
    }

    // Process each group of fields
    const processedFieldsArray = Object.values(fieldsByObjectInstanceId).map(
      (fieldsGroup) => {
        // There might be multiple fields in the group, but they all share the same ObjectInstanceId
        const fieldInstance = fieldsGroup[0]; // Representative field instance

        // Determine the value
        let value: string | number | boolean = normalizeValue(
          forcedValue !== undefined
            ? forcedValue
            : mappedIdsObj?.forcedValue !== undefined
            ? mappedIdsObj.forcedValue
            : fieldInstance?.FieldValue ??
              fieldValue ??
              getDefaultValueByType(currentKey, selectedCONST),
          currentKey,
          selectedCONST
        );

        // Check if value is numeric string and no field definition types exist
        if (typeof value === 'string' && /^\d+$/.test(value)) {
          const userOptionMappings = flattenUserOptions(dealSummary);
          value = userOptionMappings[parseInt(value.toString())] || value;
        }

        // Check if value is URL encoded and decode if necessary
        if (typeof value === 'string') {
          if (/%[0-9A-F]{2}/i.test(value)) {
            try {
              value = decodeURIComponent(value);
            } catch (error) {
              console.error('Error decoding URL encoded content:', error);
            }
          }

          // After decoding, check if it contains HTML tags and strip them if it does
          if (/<[^>]*>/g.test(value)) {
            value = stripHtmlTags(value);
          }
        }

        // Replace value if it matches a keyword action
        const keywordAction = keywordActions.find(
          (action) => action.title === value
        );

        if (keywordAction) {
          value = keywordAction.action;
        }

        // Get field definition and types
        let fieldDefinition = mappedIdsObj?.FieldDefinition;
        let isHidden = false;

        // Initialize types array
        let types: ELenderAPIType[] = [];
        let error: string | null = null;

        if (fieldDefinition) {
          // Extract types from fieldDefinition
          if (Array.isArray(fieldDefinition.type)) {
            types = fieldDefinition.type;
          } else if (fieldDefinition.type) {
            types = [fieldDefinition.type];
          }

          // Handle additional types for matching entity
          const matchingEntity = fieldDefinition.newIdList?.find(
            (item) => item.entityType === entityType
          );
          if (matchingEntity?.additionalTypes) {
            types.push(...matchingEntity.additionalTypes);
          }

          if (
            types.includes(ELenderAPIType.JSON) &&
            fieldDefinition.condition
          ) {
            value = processJsonField(value, fieldDefinition.condition);
          }

          // Handle ConcatenatedBySpace type
          if (types.includes(ELenderAPIType.ConcatenatedBySpace)) {
            value = handleConcatenatedBySpace(String(value), currentKey);
          }

          // Handle special types (e.g., CascadingDropdown, EntityDependent)
          if (types.includes(ELenderAPIType.CascadingDropdown)) {
            fieldDefinition = handleCascadingDropdown(
              fieldDefinition,
              parentValues
            );
          }

          if (types.includes(ELenderAPIType.EntityDependent)) {
            isHidden = !checkEntityDependentVisibility(
              fieldDefinition.options,
              entityType
            );

            const defaultValues: Record<string, any> = {
              string: '',
              number: 0,
              boolean: false
            };

            value = defaultValues[typeof value] ?? value;
          }

          if (
            types.includes(ELenderAPIType.Conversion) &&
            fieldDefinition.options
          ) {
            // Type guard to check if we're working with ILabelValueOption array
            const isLabelValueOptions = (
              options: string[] | ILabelValueOption[]
            ): options is ILabelValueOption[] => {
              return (
                options.length > 0 &&
                typeof options[0] === 'object' &&
                options[0] !== null &&
                'label' in options[0]
              );
            };

            if (isLabelValueOptions(fieldDefinition.options)) {
              // Find the option that includes our entity type label (more flexible matching)
              const matchingOption = fieldDefinition.options.find(
                (option) =>
                  option.label
                    .toLowerCase()
                    .includes(entityType.toLowerCase()) ||
                  entityType.toLowerCase().includes(option.label.toLowerCase())
              );

              // If we found a matching option and its value is not null, use its value
              if (matchingOption && matchingOption.value !== null) {
                value = matchingOption.value;
              }
            }
          }

          if (
            (types.includes(ELenderAPIType.Date) ||
              types.includes(ELenderAPIType.DateTime)) &&
            fieldDefinition.requirement
          ) {
            const { pattern, message } = fieldDefinition.requirement;

            if (pattern && typeof value === 'string' && value !== '') {
              const type = types.includes(ELenderAPIType.DateTime)
                ? ELenderAPIType.DateTime
                : ELenderAPIType.Date;

              const validation = validateDate(value, pattern, type);
              if (!validation.isValid) {
                error = message || validation.error;
              } else {
                value = validation.normalizedValue;
              }
            }
          }
        }

        // Determine if the field is read-only
        const isReadonly =
          mappedIdsObj?.isReadonly !== undefined
            ? mappedIdsObj?.isReadonly
            : !isEmptyOrTrue(value)
            ? true
            : false;

        return {
          value,
          FieldDefinitionId: fieldDefinitionId,
          ObjectInstanceId: fieldInstance?.ObjectInstanceId ?? 0,
          FieldDefinition: fieldDefinition
            ? { ...fieldDefinition, type: types }
            : undefined,
          isRequired: mappedIdsObj?.required ?? false,
          isReadonly,
          isHidden,
          infoField: mappedIdsObj?.info,
          error
        };
      }
    );

    // Determine if the property should be an array based on the proposal structure
    const shouldReturnArray = processedFieldsArray.length > 1;
    if (shouldReturnArray) {
      return processedFieldsArray;
    } else {
      return processedFieldsArray[0];
    }
  };

  /**
   * Handles cascading dropdown fields by updating options based on parent values.
   */
  const handleCascadingDropdown = (
    fieldDefinition: LenderFieldDefinition,
    parentValues: Record<string, IStructuredField>
  ): LenderFieldDefinition => {
    const dependsOn = fieldDefinition.dependsOn;
    if (dependsOn) {
      const parentFieldValues = Array.isArray(dependsOn)
        ? dependsOn.map((dep) => parentValues[dep]?.value)
        : [parentValues[dependsOn]?.value];

      return {
        ...fieldDefinition,
        options: fieldDefinition.getOptions?.(...parentFieldValues) ?? []
      };
    }
    return fieldDefinition;
  };

  type ProcessStructureOutput<T> = T extends any[]
    ? any[]
    : T extends object
    ? { [K in keyof T]: any }
    : IStructuredField | IStructuredField[];

  interface IProcessContext {
    arrayKeys: string[];
    maxLength: number;
    isProcessingArray?: boolean;
    arrayFields?: Map<
      string,
      {
        fields: Map<string, IStructuredField[]>;
        maxLength: number;
      }
    >;
  }

  const expandParentObject = (
    obj: any,
    arrayFields: Map<string, IStructuredField[]>,
    maxLength: number
  ) => {
    const result: any = [];

    for (let i = 0; i < maxLength; i++) {
      const instance = { ...obj };

      for (const [fieldName, values] of arrayFields.entries()) {
        instance[fieldName] = values[i] || values[values.length - 1];
      }

      result.push(instance);
    }

    return result;
  };

  /**
   * Recursively processes a given structure, handling arrays, objects, and primitive values.
   * Depending on the type of the input, it processes arrays by mapping over elements,
   * objects by iterating over their keys, and primitive values through a specific processing function.
   *
   * @template T - The type of the structure to process.
   * @param {T} obj - The structure to process (can be an array, object, or primitive).
   * @param {TTypeSafeRecord<T> | undefined} mappedIdsObj - A mapping object that associates IDs with elements in the structure.
   * @param {string} currentKey - The current key path used for identifying the nested element.
   * @param {Record<string, IStructuredField>} parentValues - A record of parent values for context during processing.
   * @returns {ProcessStructureOutput<T>} - The processed structure with elements transformed as per defined logic.
   */

  const processStructure = <T>(
    obj: T,
    mappedIdsObj: TTypeSafeRecord<T> | undefined,
    currentKey: string,
    parentValues: Record<string, IStructuredField> = {},
    context: IProcessContext = { arrayKeys: [], maxLength: 0 },
    expectedShape: any
  ): ProcessStructureOutput<T> => {
    // Helper function to check parent type
    const getParentType = (
      key: string,
      originalObj: any
    ): 'array' | 'object' | null => {
      if (!key.includes('.')) return null; // No parent

      const pathParts = key.split('.');
      pathParts.pop(); // Remove the current field
      let current = originalObj;

      // Traverse the path to find parent
      for (const part of pathParts) {
        if (!current) return null;
        // Handle array indices in the path
        if (part.includes('[') && part.includes(']')) {
          const arrayName = part.split('[')[0];
          current = current[arrayName];
          if (!Array.isArray(current)) return null;
        } else {
          current = current[part];
        }
      }

      return Array.isArray(current) ? 'array' : 'object';
    };

    // Helper function to check if a field should be hidden
    const shouldHideField = (mappedId: IMappedLenderIds): boolean => {
      if (
        !mappedId ||
        !mappedId.FieldDefinition ||
        !mappedId.FieldDefinition.type
      ) {
        return false;
      }

      const types = Array.isArray(mappedId.FieldDefinition.type)
        ? mappedId.FieldDefinition.type
        : [mappedId.FieldDefinition.type];

      return types.includes(ELenderAPIType.Hide);
    };

    if (Array.isArray(obj)) {
      // Array processing logic
      if (context.isProcessingArray) {
        const flattenedArray = obj.flatMap((item, index) =>
          processStructure(
            item,
            Array.isArray(mappedIdsObj) ? mappedIdsObj[index] : mappedIdsObj,
            `${currentKey}[${index}]`,
            parentValues,
            context,
            expectedShape?.[0]
          )
        );
        return flattenedArray as ProcessStructureOutput<T>;
      }

      const processedArray = obj
        .map((item, index: number) =>
          processStructure(
            item,
            Array.isArray(mappedIdsObj) ? mappedIdsObj[index] : mappedIdsObj,
            `${currentKey}[${index}]`,
            parentValues,
            { ...context, isProcessingArray: true },
            expectedShape?.[0]
          )
        )
        .flat()
        .filter(Boolean); // Filter out any null/undefined results

      return processedArray as ProcessStructureOutput<T>;
    } else if (typeof obj === 'object' && obj !== null) {
      // Check if the whole object should be hidden
      if (shouldHideField(mappedIdsObj as IMappedLenderIds)) {
        return null as unknown as ProcessStructureOutput<T>;
      }

      const processedObj = {} as {
        [K in keyof T]: ProcessStructureOutput<T[K]>;
      };

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const mappedId = mappedIdsObj
            ? mappedIdsObj[key as keyof T]
            : undefined;

          // Skip this field if it has the Hide type
          if (shouldHideField(mappedId as IMappedLenderIds)) {
            continue;
          }

          const processedValue = processStructure(
            value,
            mappedId as any,
            currentKey ? `${currentKey}.${key}` : key,
            parentValues,
            context,
            expectedShape?.[key]
          );

          // Only add non-null processed values
          if (processedValue !== null) {
            const isDefinedAsArray = Array.isArray(expectedShape?.[key]);

            processedObj[key as keyof T] = isDefinedAsArray
              ? ((Array.isArray(processedValue)
                  ? processedValue
                  : [processedValue]) as ProcessStructureOutput<T[typeof key]>)
              : ((!Array.isArray(processedValue)
                  ? processedValue
                  : processedValue[0]) as ProcessStructureOutput<
                  T[typeof key]
                >);
          }
        }
      }

      const arrayFieldContext = context.arrayFields?.get(currentKey);
      if (arrayFieldContext) {
        return expandParentObject(
          processedObj,
          arrayFieldContext.fields,
          arrayFieldContext.maxLength
        ) as ProcessStructureOutput<T>;
      }

      return processedObj as ProcessStructureOutput<T>;
    } else {
      // Check if this primitive field should be hidden
      if (shouldHideField(mappedIdsObj as IMappedLenderIds)) {
        return null as unknown as ProcessStructureOutput<T>;
      }

      // Process primitive field
      const processedField = processField(
        obj as unknown as string | number | boolean,
        mappedIdsObj as IMappedLenderIds,
        currentKey,
        parentValues
      );

      // If the processed field is an array, check parent type
      if (Array.isArray(processedField)) {
        const parentType = getParentType(currentKey, selectedCONST);

        // For array parents or no parent, continue with array handling
        const pathParts = currentKey.split('.');
        const fieldName = pathParts.pop()!;
        const parentKey = pathParts.join('.');

        if (!context.arrayFields) {
          context.arrayFields = new Map();
        }

        if (!context.arrayFields.has(parentKey)) {
          context.arrayFields.set(parentKey, {
            fields: new Map(),
            maxLength: 0
          });
        }

        const parentContext = context.arrayFields.get(parentKey)!;

        // Filter out hidden fields from the array
        const visibleFields = processedField.filter((field) => {
          const types = field.FieldDefinition?.type || [];
          return !Array.isArray(types)
            ? types !== ELenderAPIType.Hide
            : !types.includes(ELenderAPIType.Hide);
        });

        if (visibleFields.length === 0) {
          return null as unknown as ProcessStructureOutput<T>;
        }

        parentContext.fields.set(fieldName, visibleFields);
        parentContext.maxLength = Math.max(
          parentContext.maxLength,
          visibleFields.length
        );

        return visibleFields[0] as ProcessStructureOutput<T>;
      }

      // Check if the processed single field should be hidden
      if (processedField.FieldDefinition?.type) {
        const types = Array.isArray(processedField.FieldDefinition.type)
          ? processedField.FieldDefinition.type
          : [processedField.FieldDefinition.type];

        if (types.includes(ELenderAPIType.Hide)) {
          return null as unknown as ProcessStructureOutput<T>;
        }
      }

      return processedField as ProcessStructureOutput<T>;
    }
  };

  const context: IProcessContext = {
    arrayKeys: [],
    maxLength: 0,
    arrayFields: new Map()
  };

  const result = processStructure(
    selectedCONST,
    mappedIds,
    '',
    {},
    context,
    selectedCONST
  );
  const cleanupResult = (obj: any): any => {
    if (obj === null || obj === undefined) {
      return null;
    }

    if (Array.isArray(obj)) {
      return obj.map(cleanupResult).filter(Boolean);
    }

    if (typeof obj === 'object') {
      const cleaned: Record<string, any> = {};

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const cleanedValue = cleanupResult(obj[key]);
          if (cleanedValue !== null) {
            cleaned[key] = cleanedValue;
          }
        }
      }

      return cleaned;
    }

    return obj;
  };

  const cleanedResult = cleanupResult(result);
  if (Array.isArray(cleanedResult)) {
    return cleanedResult[0];
  } else if (typeof cleanedResult === 'object' && cleanedResult !== null) {
    return cleanedResult;
  } else {
    console.warn('Unexpected result type:', typeof result);
    return {} as TLenderAPIInterfaces;
  }
};

const createEmptyInternalStructure = ({
  selectedCONST,
  mappedIds
}: {
  selectedCONST: TLenderAPIInterfaces;
  mappedIds: TMappedIds | undefined;
}): TLenderAPIInterfaces => {
  const processStructure = <T>(
    obj: T,
    mappedIdsObj: TTypeSafeRecord<T> | undefined,
    currentKey: string
  ): T => {
    if (Array.isArray(obj)) {
      return obj.map((item, index) =>
        processStructure(
          item,
          Array.isArray(mappedIdsObj) ? mappedIdsObj[index] : mappedIdsObj,
          `${currentKey}[${index}]`
        )
      ) as unknown as T;
    } else if (typeof obj === 'object' && obj !== null) {
      const processedObj = {} as T;

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const mappedId = mappedIdsObj
            ? mappedIdsObj[key as keyof T]
            : undefined;

          processedObj[key as keyof T] = processStructure(
            value,
            mappedId as any,
            key
          );
        }
      }

      return processedObj;
    } else {
      return createStructuredField(
        obj,
        mappedIdsObj as IMappedLenderIds
      ) as unknown as T;
    }
  };

  return processStructure(selectedCONST, mappedIds, '');
};

const createStructuredField = (
  value: any,
  mappedIdsObj: IMappedLenderIds
): IStructuredField => {
  return {
    value: value,
    FieldDefinitionId: mappedIdsObj?.FieldDefinitionId ?? 0,
    ObjectInstanceId: 0,
    FieldDefinition: mappedIdsObj?.FieldDefinition,
    isRequired: mappedIdsObj?.required ?? false,
    isReadonly: true,
    isHidden: false,
    infoField: mappedIdsObj?.info
  };
};

/**
 * Flattens a CompleteObjectInstanceDict into an array of IExtraFallenCompleteObjectInstance
 * @param {CompleteObjectInstanceDict} completeObjectInstanceDict - The complete object instance dictionary
 * @returns {IExtraFallenCompleteObjectInstance[]} Flattened array of field instances
 */
const flattenCompleteObjectInstanceDict = (
  input:
    | CompleteObjectInstanceDict
    | CompleteObjectInstance[]
    | (CompleteObjectInstanceDict | CompleteObjectInstance[])[]
): IExtraFallenCompleteObjectInstance[] => {
  const flattenedInstances: IExtraFallenCompleteObjectInstance[] = [];
  if (Array.isArray(input)) {
    input.forEach((item) => {
      if (item) {
        const results = flattenCompleteObjectInstanceDict(item);
        flattenedInstances.push(...results);
      }
    });
    return flattenedInstances;
  }

  if (!Array.isArray(input) && typeof input === 'object') {
    const isDict = Object.keys(input).some((key) => !isNaN(Number(key)));

    if (isDict) {
      Object.values(input).forEach(
        (completeObjectInstance: CompleteObjectInstance) => {
          if (completeObjectInstance) {
            const fieldInstanceList = getFieldInstances(completeObjectInstance);
            fieldInstanceList.forEach((fieldInstance: FieldInstance) => {
              flattenedInstances.push({
                ...fieldInstance,
                isSelected: completeObjectInstance.ObjectInstance.Selected
              });
            });
          }
        }
      );
    } else {
      const completeObjectInstance = input as unknown as CompleteObjectInstance;
      if (completeObjectInstance && completeObjectInstance.ObjectInstance) {
        const fieldInstanceList = getFieldInstances(completeObjectInstance);
        fieldInstanceList.forEach((fieldInstance: FieldInstance) => {
          flattenedInstances.push({
            ...fieldInstance,
            isSelected: completeObjectInstance.ObjectInstance.Selected
          });
        });
      }
    }
  }

  return flattenedInstances;
};

export {
  flattenCompleteObjectInstanceDict,
  proposalCreation as createInternalStructure,
  createEmptyInternalStructure
};

/**
 * Checks if a field should be visible based on entity type
 * @param {(string[]|ILabelValueOption[])} options - The options to check against
 * @param {string} entityType - The type of entity
 * @returns {boolean} Whether the field should be visible
 */
const checkEntityDependentVisibility = (
  options: string[] | ILabelValueOption[] | undefined,
  entityType: string
): boolean => {
  if (!options) return false;

  if (typeof options[0] === 'string') {
    return (options as string[]).includes(entityType);
  } else {
    return (options as ILabelValueOption[]).some(
      (option) => option.label === entityType
    );
  }
};

/**
 * Groups an array of items by a key
 * @template T
 * @template {keyof any} K
 * @param {T[]} array - The array to group
 * @param {function(T): K} keyGetter - Function to get the grouping key from an item
 * @returns {Record<K, T[]>} Grouped object
 */
const groupBy = <T, K extends keyof any>(
  array: T[],
  keyGetter: (item: T) => K
): Record<K, T[]> => {
  return array.reduce((result, currentItem) => {
    const key = keyGetter(currentItem);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(currentItem);
    return result;
  }, {} as Record<K, T[]>);
};
/**
 * Determines if content is URL encoded by checking for typical encoding patterns
 * @param content The string content to check
 * @returns boolean indicating if content appears to be URL encoded
 */
export const isUrlEncoded = (content: string): boolean => {
  // Check for common URL encoding patterns
  const urlEncodedPattern = /%[0-9A-F]{2}/i;
  return urlEncodedPattern.test(content);
};

/**
 * Flattens dealSummary to get UserOption mappings
 */
const flattenUserOptions = (
  dealSummary: IDealSummary
): Record<string, string> => {
  const mappings: Record<string, string> = {};

  const traverse = (obj: any) => {
    if (!obj) return;

    if (Array.isArray(obj)) {
      obj.forEach((item) => traverse(item));
      return;
    }

    if (typeof obj === 'object') {
      // Check if current object is a UserOption
      if (obj.__typename === 'UserOption' && obj.Id && obj.Title) {
        mappings[obj.Id.toString()] = obj.Title;
      }

      // Recurse through all properties
      Object.values(obj).forEach((value) => traverse(value));
    }
  };

  traverse(dealSummary);

  return mappings;
};

/**
 * Strips HTML tags from a string and returns just the text content
 */
const stripHtmlTags = (html: string): string => {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || '';
};

const validateDateTime = (value: string, pattern: string) => {
  let unescapedPattern = pattern
    .replace(/\\\\/g, '\\')
    .replace(/^\^/, '')
    .replace(/\$$/, '');

  let normalizedValue = value.replace(/\.\d{3}Z$/, '').replace(/\//g, '-');

  if (normalizedValue.includes('T')) {
    const [datePart, timePart] = normalizedValue.split('T');
    normalizedValue = `${datePart}T00:00:00`;
  } else if (normalizedValue.match(/^\d{4}-\d{2}-\d{2}\d{2}:\d{2}:\d{2}$/)) {
    normalizedValue = normalizedValue.replace(
      /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/,
      '$1T$2'
    );
  } else if (normalizedValue.match(/^\d{4}-\d{2}-\d{2}[T ]?\d+:?\d*:?\d*$/)) {
    normalizedValue = normalizedValue.substring(0, 10) + 'T00:00:00';
  } else if (normalizedValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
    normalizedValue = `${normalizedValue}T00:00:00`;
  }

  try {
    const datePart = normalizedValue.split('T')[0];
    const dateRegex = new RegExp(unescapedPattern);
    const dateMatch = datePart.match(dateRegex);

    if (!dateMatch) {
      return {
        isValid: false,
        normalizedValue,
        error: 'Invalid datetime format'
      };
    }

    const momentFormat = 'YYYY-MM-DDTHH:mm:ss';
    const parsedDate = moment(normalizedValue, momentFormat, true);

    if (!parsedDate.isValid()) {
      return {
        isValid: false,
        normalizedValue,
        error: 'Invalid datetime'
      };
    }

    return {
      isValid: true,
      normalizedValue,
      error: null
    };
  } catch (err) {
    return {
      isValid: false,
      normalizedValue,
      error: 'Invalid datetime format'
    };
  }
};

const validateDateOnly = (value: string, pattern: string) => {
  let unescapedPattern = pattern
    .replace(/\\\\/g, '\\')
    .replace(/^\^/, '')
    .replace(/\$$/, '');

  // For date-only, strip any time component and normalize slashes
  let normalizedValue = value
    .replace(/T.*$/, '')
    .replace(/\.\d{3}Z$/, '')
    .replace(/\//g, '-');

  const dateParts = normalizedValue.split('-');

  if (dateParts.length === 3) {
    const [part1, month, year] = dateParts;

    if (part1 === '00') {
      normalizedValue = `${year}-${month}-01`;
    } else if (parseInt(part1) <= 31 && part1.length === 2) {
      normalizedValue = `${year}-${month}-${part1}`;
    }
  }

  try {
    const dateRegex = new RegExp(unescapedPattern);
    const dateMatch = normalizedValue.match(dateRegex);

    if (!dateMatch) {
      return {
        isValid: false,
        normalizedValue,
        error: 'Invalid date format'
      };
    }

    const momentFormat = patternToMomentFormat(pattern);
    const parsedDate = moment(normalizedValue, momentFormat, true);

    if (!parsedDate.isValid()) {
      return {
        isValid: false,
        normalizedValue,
        error: 'Invalid date'
      };
    }

    return {
      isValid: true,
      normalizedValue,
      error: null
    };
  } catch (err) {
    return {
      isValid: false,
      normalizedValue,
      error: 'Invalid date format'
    };
  }
};

const validateDate = (value: string, pattern: string, type: ELenderAPIType) => {
  return type === ELenderAPIType.DateTime
    ? validateDateTime(value, pattern)
    : validateDateOnly(value, pattern);
};

/**
 * Processes a field with ELenderAPIType.JSON type to extract the specified value
 * based on the condition in the field definition
 *
 * @param value - The JSON string value to parse
 * @param condition - The property name to extract from the parsed JSON
 * @returns The extracted value or empty string if not found
 */
const processJsonField = (
  value: string | number | boolean,
  condition?: string
): string | number | boolean => {
  if (!condition || typeof value !== 'string') {
    return value;
  }

  try {
    const jsonData = JSON.parse(value);

    if (jsonData && typeof jsonData === 'object' && condition in jsonData) {
      return jsonData[condition];
    }

    if (condition.includes('.')) {
      const properties = condition.split('.');
      let currentValue = jsonData;

      for (const prop of properties) {
        if (
          currentValue &&
          typeof currentValue === 'object' &&
          prop in currentValue
        ) {
          currentValue = currentValue[prop];
        } else {
          return '';
        }
      }

      return currentValue;
    }

    return '';
  } catch (error) {
    console.error('Error parsing JSON field:', error);
    return '';
  }
};
