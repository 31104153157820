import React from 'react';
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { FolderOpen, Computer } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IFieldsProps } from 'components/Fields/InlineTextField/interface';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    marginTop: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 2)
  },
  listItemIcon: {
    minWidth: 40
  }
}));

interface IUploadMethodPopoverProps {
  inlineFieldProps?: IFieldsProps;
  anchorEl: HTMLElement | null;
  handleMenuClose: () => void;
  handleFileExplorerOpen: () => void;
  handleLocalDiskBrowse: () => void;
}

/**
 * UploadMethodPopover component.
 *
 * This component displays a popover with options for uploading files.
 * It provides two options: opening a file explorer or opening file storage.
 *
 * @param {IUploadMethodPopoverProps} props - The props for the component.
 * @returns {React.ReactElement | null} The rendered component or null if it shouldn't be displayed.
 */
const UploadMethodPopover = ({
  inlineFieldProps,
  anchorEl,
  handleMenuClose,
  handleFileExplorerOpen,
  handleLocalDiskBrowse
}: IUploadMethodPopoverProps) => {
  const classes = useStyles();
  const { landingpage } = useTypedSelector((s) => s.utility);
  const showPopover = inlineFieldProps !== undefined && !landingpage;

  /**
   * Effect to handle the case when the popover should not be shown.
   * In this case, it directly triggers the local disk browse action and closes the menu.
   */
  React.useEffect(() => {
    if (!showPopover && anchorEl) {
      handleLocalDiskBrowse();
      handleMenuClose();
    }
  }, [showPopover, anchorEl, handleLocalDiskBrowse, handleMenuClose]);
  if (!showPopover) return <div />;

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      className={classes.popover}>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        style={{
          padding: '16px 16px 8px'
        }}>
        Please select a method of uploading:
      </Typography>
      <List>
        <ListItem
          button
          onClick={handleFileExplorerOpen}
          className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <FolderOpen />
          </ListItemIcon>
          <ListItemText primary="Open File Explorer" />
        </ListItem>
        {inlineFieldProps?.type !== 'File Path' && (
          <ListItem
            button
            onClick={handleLocalDiskBrowse}
            className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <Computer />
            </ListItemIcon>
            <ListItemText
              primary={
                showPopover ? 'Open File Storage' : 'Browse from Local Disk'
              }
            />
          </ListItem>
        )}
      </List>
    </Popover>
  );
};

export default UploadMethodPopover;
