import { useQuery } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  GET_DLL_ASSET_BRANDS,
  GET_DLL_ASSET_MODELES_BY_BRAND_AND_TYPE
} from 'graphql/LenderAPI/queries';
import { ELender } from '../../../interface';

export interface DLLAssetBrand {
  name: string;
  assetTypeName: string;
  assetCategoryName: string;
  masterAssetTypeName: string;
}

export interface DLLAssetModel {
  id: string;
  name: string;
  assetBrandName: string;
  assetTypeName: string;
  assetCategoryName: string;
  masterAssetTypeName: string;
  modifiedDate: string;
  listPrice: {
    default: number;
    defaultPercentageOf: string;
    max: number;
    maxPercentageOf: string;
    min: number;
    minPercentageOf: string;
  };
  description: {
    allowed: boolean;
    required: boolean;
  };
  quantity: {
    maximumLimit: boolean;
    maximumValue: number;
  };
  conditions: string[];
  age: Array<{
    maximumValue: number;
    maximumValueUnit: string;
    futureModelYearAllowed: boolean;
    conditions: string[];
  }>;
  cashflows: Array<{
    group: Array<{
      cashflowType: string;
      id: string;
      name: string;
      interpolation: string;
      excludeFromAPR: boolean;
      usageUnits: string;
      lookups: Array<{
        outputs: {
          annual: boolean;
          default: number;
          defaultPercentageOf: string;
          max: number;
          maxPercentageOf: string;
          min: number;
          minPercentageOf: string;
        };
      }>;
    }>;
  }>;
  financialProducts: Array<{
    id: string;
    url: string;
  }>;
}

export const getDLLAssetBrands = async (dealId: string) => {
  if (!dealId) return [];

  try {
    const { data } = await apolloClient.query<{
      GetDLLAssetBrands: DLLAssetBrand[];
    }>({
      query: GET_DLL_ASSET_BRANDS,
      variables: { dealId }
    });

    return data?.GetDLLAssetBrands || [];
  } catch (error) {
    console.error('Error fetching DLL asset brands:', error);
    return [];
  }
};

export const getDLLAssetModelsByBrandAndType = async (
  brandName: string,
  typeName: string,
  dealId: string
) => {
  if (!brandName || !typeName) return [];

  try {
    const { data } = await apolloClient.query<{
      GetDLLAssetModelsByBrandAndType: DLLAssetModel[];
    }>({
      query: GET_DLL_ASSET_MODELES_BY_BRAND_AND_TYPE,
      fetchPolicy: 'network-only',
      variables: { brandName, typeName, dealId }
    });

    return data?.GetDLLAssetModelsByBrandAndType || [];
  } catch (error) {
    console.error('Error fetching DLL asset models:', error);
    return [];
  }
};
