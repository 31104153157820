import { ICreateDocumentInput } from 'graphql/FileStorageAPI/interface';
import { BugTracker } from 'Utils/Bugtracker';

/**
 * Creates a new document using the provided input.
 * @param {ICreateDocumentInput} input - The input data for creating the document.
 * @returns {Promise<{ data: { createDocument: {uuid: string} }; loading: boolean } | ApolloError>} - A promise resolving to the created document's UUID or an ApolloError.
 */
export const CreateFraudulentDocumentUUID = async ({
  input
}: {
  input: ICreateDocumentInput;
}): Promise<string | undefined> => {
  try {
    const query = `
      mutation CreateDocument($input: CreateDocumentInput!) {
        createDocument(input: $input) {
          uuid
        }
      }`;

    const formData = new FormData();

    formData.append(
      'operations',
      JSON.stringify({
        query,
        variables: {
          input: {
            ...input,
            file: null
          }
        }
      })
    );

    formData.append(
      'map',
      JSON.stringify({
        '0': ['variables.input.file']
      })
    );

    formData.append('0', input.file);

    const apiUrl =
      process.env.REACT_APP_OFFKEY_GRAPH_URL || 'http://localhost:4000/';

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'apollo-require-preflight': 'true'
      },
      body: formData
    });

    let result;
    try {
      result = await response.json();
    } catch (e) {
      console.error('Failed to parse response as JSON:', e);
      const textResponse = await response.text();
      console.log('Raw response:', textResponse);
      throw new Error('Invalid JSON response from server');
    }

    if (result?.data?.createDocument?.uuid) {
      return result.data.createDocument.uuid;
    }

    if (result.errors) {
      const formattedErrors = result.errors.map((err: any) => {
        return {
          message: err.message,
          path: err.path,
          extensions: err.extensions
        };
      });
      console.error('Formatted errors:', formattedErrors);
    }

    return undefined;
  } catch (e) {
    const error = e as Error;
    console.error('CreateFraudulentDocumentUUID error:', error.message);
    console.error('Error stack:', error.stack);

    if (
      error.name === 'TypeError' &&
      error.message.includes('Failed to fetch')
    ) {
      console.error(
        'Network error - check if server is running and accessible'
      );
    }

    if (typeof BugTracker !== 'undefined') {
      BugTracker.notify(error);
    }

    return undefined;
  }
};
